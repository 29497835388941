<template>
  <b-nav-item-dropdown right>
    <template #button-content>
      <span v-if="user" class="d-inline-flex align-items-center justify-content-start">
        <b-avatar
          class="mr-1"
          variant="info"
          size="2rem"
          :src="`${user.avatar ? `/storage/gallery/media/${ user.avatar }` : ''}`"
        />
        <div class="d-flex flex-column">
          <span v-if="user.displayName">
            {{ user.displayName }}
          </span>
          <span class="small">{{ user.email }}</span>
        </div>
      </span>
    </template>
    <b-dropdown-item>
      <nuxt-link to="profile" class="">
        {{ $t('eUserNav_profile') }}
      </nuxt-link>
    </b-dropdown-item>
    <b-dropdown-item @click="signOut">
      {{ $t('eUserNav_signOut') }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import Cookie from 'js-cookie'
export default {
  name: 'UserHeaderNav',
  computed: {
    user () {
      return this.$auth && this.$auth.user
    }
  },
  methods: {
    signOut () {
      Cookie.set('auth.redirect', this.$route.fullPath)
      this.$auth.logout()
    }
  }
}
</script>

<style scoped>

</style>
