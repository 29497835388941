<template>
  <span>
    <b-link
      v-if="!isLocked('customers', uId) && uName"
      id="profile-link"
      :class="`text-${nameVariant}`"
      @click="onClick"
    >
      <slot>
        {{ uName }}
      </slot>
    </b-link>
    <b-link
      v-else-if="!isLocked('customers', uId)"
      :class="`text-${nameVariant}`"
      @click="onClick"
    >
      <slot>
        {{ uId ? `User ${uId}` : '' }}
      </slot>
    </b-link>
    <span v-else>
      <span
        :id="`profile-link-${uniqKey}`"
        class="text-secondary"
        :title="``"
      >
        <slot>
          {{ uName }}
        </slot>
      </span>
      <b-tooltip
        :target="`profile-link-${uniqKey}`"
        placement="bottom"
        variant="warning"
        class="text-left"
      >
        {{ $t('eLt_locked', { email: isLockedByUser.email, id: isLockedByUser.by }) }}
      </b-tooltip>
    </span>
  </span>
</template>
<script>
import { mapGetters } from 'vuex'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'UserProfileLink',
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    userId: {
      type: [Number, String, null],
      default: null
    },
    userName: {
      type: [String, null],
      default: null
    },
    nameVariant: {
      type: String,
      default: 'info'
    }
  },
  computed: {
    ...mapGetters({
      isLocked: 'shared/isLocked',
      isLockedBy: 'shared/isLockedBy'
    }),
    uId () {
      return this.user?.user_id || this.userId
    },
    uName () {
      return this.user?.display_name || this.userName
    },
    uniqKey () {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8)
    },
    isLockedByUser () {
      const user = this.isLockedBy('customers', this.uId)
      if (user) {
        return user
      }
      return false
    }
  },
  methods: {
    onClick () {
      if (!isServer) {
        window.open(`/customers/${this.uId}`, '_blank').focus()
      }
      // this.$openGlobal('CustomerEditForm', this.uId, 'customers')
      this.$emit('click', this.uId)
    }
  }
}
</script>

<style scoped>

</style>
