<template>
  <b-btn
    v-if="!isLocked('customers', user && user.id) && user.user_profile"
    :variant="variant"
    :size="size"
    @click="onClick"
  >
    <slot>
      {{ isUserExists && isProfileExists && user.user_profile.display_name }}
    </slot>
  </b-btn>
  <b-btn
    v-else-if="!isLocked('customers', user && user.id)"
    :variant="variant"
    :size="size"
    @click="onClick"
  >
    <slot>
      {{ isUserExists && isProfileExists && user.id ? `User ${user.id}` : '' }}
    </slot>
  </b-btn>
  <span
    v-else
  >
    <b-btn
      :id="`profile-btn-${uniqKey}`"
      :variant="variant"
      :size="size"
      disabled
    >
      <slot>
        {{ isUserExists && isProfileExists && user.id ? `User ${user.id}` : '' }}
      </slot>
    </b-btn>
    <b-tooltip
      :target="`profile-btn-${uniqKey}`"
      placement="bottom"
      variant="warning"
      class="text-left"
    >
      {{ $t('eLt_locked', { email: isLockedByUser.email, id: isLockedByUser.by }) }}
    </b-tooltip>
  </span>
</template>
<script>
import { mapGetters } from 'vuex'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'UserProfileBtn',
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'sm'
    },
    user: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      isLocked: 'shared/isLocked',
      isLockedBy: 'shared/isLockedBy'
    }),
    isUserExists () {
      return this.user && this.user.id
    },
    isProfileExists () {
      return this.user &&
        this.user.user_profile &&
        this.user.user_profile.id
    },
    uniqKey () {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8)
    },
    isLockedByUser () {
      const user = this.isLockedBy('customers', this.user && this.user.id)
      if (user) {
        return user
      }
      return false
    }
  },
  methods: {
    onClick () {
      if (!isServer) {
        window.open(`/customers/${this.user.id}`, '_blank').focus()
      }
      // this.$openGlobal('CustomerEditForm', this.user.id, 'customers')
      this.$emit('click', this.user.id)
    }
  }
}
</script>

<style scoped>

</style>
